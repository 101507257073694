* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.read,
.read-more,
.sign-up,
.text1 {
  display: flex;
  height: 91.4vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.read {
  background-image: url('./images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.read-more {
  background-image: url('./images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.text1 {
  background-image: url('./images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

@media (max-width: 1024px) {
  .home,
  .read,
  .read-more,
  .sign-up,
  .text1 {
    height: 100vh; 
    font-size: 6vw;
  }
}